import React from 'react';

interface SearchIconProps {
  className?: string;
}

const SearchIcon: React.FC<SearchIconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      fill="#00B481"
      d="M7.384 14.64a7.16 7.16 0 117.16-7.16 7.169 7.169 0 01-7.16 7.16zm0-12.728a5.569 5.569 0 105.57 5.569 5.575 5.575 0 00-5.57-5.57z"
    ></path>
    <path
      fill="#00B481"
      d="M4.997 7.48h-1.59a3.982 3.982 0 013.977-3.977v1.591a2.387 2.387 0 00-2.387 2.387zM17.493 16.465l-3.386-3.386c-.34.408-.716.785-1.125 1.125l3.386 3.386a.795.795 0 001.125-1.125z"
    ></path>
  </svg>
);

export default SearchIcon;
