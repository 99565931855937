import { Link } from 'gatsby';
import React from 'react';
import { html2react } from 'helpers/parser';
import { getResourceUrl } from 'helpers/resources';
import { ResourceType } from 'types/resource-type';
import SearchIcon from 'components/atoms/SearchIcon';
import NewsletterBox from 'components/molecules/NewsletterBox';
import ResourceTile from 'components/molecules/ResourceTile';
import ResourceTypeTag from 'components/molecules/ResourceTypeTag';

interface ResourceSummary {
  title: string;
  type: ResourceType;
  slug: string;
  excerpt?: string;
  thumbnailUrl?: string;
}

interface ResourcesHeroProps {
  title: string;
  query: string;
  featuredResource?: ResourceSummary;
  popularResources: ResourceSummary[];
  onSearchQueryChange: (query: string) => void;
}

const ResourcesHero: React.FC<ResourcesHeroProps> = ({
  title,
  query,
  featuredResource,
  popularResources,
  onSearchQueryChange,
}) => {
  const showSearchResults = query?.trim().length > 0;

  return (
    <div className="pb-20 bg-aqua-light">
      <div className="px-6 u-container lg:px-md xl:px-0">
        <div className="pt-32 pb-12 md:pt-56 md:pb-24">
          <h1 className="items-center justify-between u-h1 md:flex">
            <div className="mb-4 md:mb-0">
              {showSearchResults
                ? `Search results for ‘${query}’`
                : html2react(title)}
            </div>
            <div className="relative flex w-full max-w-full md:w-80">
              <input
                type="text"
                className="w-full py-3 pl-16 pr-4 text-xl font-normal tracking-tighter bg-white text-paragraph focus:outline-none"
                placeholder="Search"
                value={query}
                onChange={(e) => onSearchQueryChange(e.target.value)}
              />
              <div className="absolute top-0 bottom-0 left-0 flex items-center justify-center w-16 h-full">
                <SearchIcon className="w-4.5 h-auto" />
              </div>
            </div>
          </h1>
        </div>
        {!showSearchResults && (
          <div className="flex flex-col space-y-12 lg:flex-row lg:space-y-0 lg:space-x-24">
            <div className="w-full lg:w-1/2">
              {featuredResource && (
                <ResourceTile
                  title={featuredResource.title}
                  url={getResourceUrl(
                    featuredResource.type,
                    featuredResource.slug,
                  )}
                  image={featuredResource.thumbnailUrl}
                  type={featuredResource.type}
                  excerpt={featuredResource.excerpt}
                  overrideColor="text-aqua"
                  large
                />
              )}
            </div>
            <div className="w-full lg:w-1/2">
              <h2 className="mt-2 mb-8 u-h3">Popular Resources</h2>

              <div className="space-y-7">
                {popularResources.map((resource, index) => (
                  <Link
                    className="block transition hover:opacity-50 focus:opacity-50"
                    to={getResourceUrl(resource.type, resource.slug)}
                    key={index}
                  >
                    <ResourceTypeTag
                      type={resource.type}
                      overrideColor="text-aqua"
                      customerStoriesFullLabel
                    />
                    <div className="font-bold text-xl lg:text-2.5xl tracking-tighter lg:tracking-tightest leading-snug">
                      {resource.title}
                    </div>
                  </Link>
                ))}
              </div>
              <NewsletterBox />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResourcesHero;
