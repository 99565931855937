import { Link } from 'gatsby';
import React from 'react';

interface DownloadLinkProps {
  title: string;
  url: string;
}

const DownloadLink: React.FC<DownloadLinkProps> = ({ title, url }) => {
  return (
    <Link
      to={url}
      className="block transition hover:opacity-50 focus:opacity-50"
    >
      <div className="uppercase text-aqua mb-0.75 tracking-tighter font-bold text-sm">
        Download
      </div>
      <div className="font-bold text-paragraph tracking-tightest text-2xl lg:text-2.5xl leading-8">
        {title}
      </div>
    </Link>
  );
};

export default DownloadLink;
