import { graphql } from 'gatsby';
import React, { useState } from 'react';
import find from 'lodash/find';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import ResourceGrid from 'components/molecules/ResourceGrid';
import TwoColumnResourceList from 'components/organisms/TwoColumnResourceList';
import CtaSection from 'components/organisms/CtaSection';
import NewsletterCtaSection from 'components/organisms/NewsletterCtaSection';
import ResourcesHero from 'components/organisms/ResourcesHero';
import SearchResults from 'components/organisms/SearchResults';

// reorder nodes based on provided list of id
const sortPopularResouces = (ids, resources) => {
  let nodes = resources.edges.map(({ node }) => node);

  return ids.map((id) => {
    let { frontmatter } = find(nodes, { id }) || {};
    return {
      title: frontmatter?.title,
      slug: frontmatter?.slug,
      type: frontmatter?.resourceType,
    };
  });
};

export const ResourcesPreview = ({ entry }) => {
  const { heroTitle, newsletterCta, cta } = entry.get('data').toJS();
  const [query, setQuery] = useState('');

  return (
    <>
      <ResourcesHero
        title={heroTitle}
        query={query}
        featuredResource={null}
        popularResources={[]}
        onSearchQueryChange={setQuery}
      />
      <NewsletterCtaSection headline={newsletterCta?.headline} />
      <CtaSection headline={cta?.headline} />
    </>
  );
};

const ResourcesPage = ({
  data: {
    page: { frontmatter: page },
    featuredResource,
    popularResources,
    siteSearchIndex: { index },
    posts,
    reports,
    customerStories,
    downloads,
    productUpdates,
    videos,
    events,
    pressReleases,
  },
  pageContext: { popularResourceIds },
}) => {
  const [query, setQuery] = useState('');
  const showSearchResults = query?.trim().length > 0;
  const sortedPopularResources = sortPopularResouces(
    popularResourceIds,
    popularResources,
  );
  
  let featured;
  if (featuredResource) {
    featured = {
      title: featuredResource.frontmatter.title,
      thumbnailUrl: featuredResource.frontmatter.featuredImage,
      excerpt: featuredResource.frontmatter.description,
      slug: featuredResource.frontmatter.slug,
      type: featuredResource.frontmatter.resourceType,
    };
  }

  return (
    <Layout headerBackground="bg-aqua bg-opacity-15">
      <SEO
        title={page.seo?.title}
        description={page.seo?.description}
        openGraphImage={page.seo?.openGraphImage}
      />

      <ResourcesHero
        title={page.heroTitle}
        query={query}
        featuredResource={featured}
        popularResources={sortedPopularResources}
        onSearchQueryChange={setQuery}
      />

      {showSearchResults ? (
        <SearchResults query={query} index={index} />
      ) : (
        <>
          {posts.edges.length > 0 && (
            <ResourceGrid
              title="Blog"
              viewAllUrl="/blog"
              cols={4}
              resources={posts.edges.map(({ node: { frontmatter } }, i) => ({
                aspectHeight: i % 2 === 0 ? 'lg:aspect-h-13' : 'lg:aspect-h-16',
                aspectWidth: i % 2 === 0 ? 'lg:aspect-w-16' : 'lg:aspect-w-16',
                title: frontmatter.title,
                url: `/blog/${frontmatter.slug}`,
                thumbnailUrl: frontmatter.featuredImage,
                excerpt: frontmatter.description,
                type: 'blog-post',
              }))}
            />
          )}

          {reports.edges.length > 0 && (
            <ResourceGrid
              title="Reports"
              viewAllUrl="/reports"
              overrideColor="text-aqua"
              cols={3}
              resources={reports.edges.map(({ node: { frontmatter } }) => ({
                title: frontmatter.title,
                url: `/reports/${frontmatter.slug}`,
                thumbnailUrl: frontmatter.heroImage,
                excerpt: frontmatter.description,
                type: 'report',
              }))}
            />
          )}

          {customerStories.edges.length > 0 && (
            <ResourceGrid
              title="Customer Stories"
              viewAllUrl="/customer-stories"
              overrideColor="text-aqua"
              cols={3}
              resources={customerStories.edges.map(
                ({ node: { frontmatter } }) => ({
                  title: frontmatter.title,
                  url: `/customer-stories/${frontmatter.slug}`,
                  thumbnailUrl: frontmatter.featuredImage,
                  excerpt: frontmatter.description,
                  type: frontmatter.resourceType,
                }),
              )}
            />
          )}

          <TwoColumnResourceList
            downloads={downloads.edges.map(({ node: { frontmatter } }) => ({
              title: frontmatter.title,
              url: `/downloads/${frontmatter.slug}`,
            }))}
            productUpdates={productUpdates.edges.map(
              ({ node: { frontmatter } }) => ({
                title: frontmatter.title,
                url: `/product-updates/${frontmatter.slug}`,
                publishDate: frontmatter.date,
              }),
            )}
          />

          {videos.edges.length > 0 && (
            <ResourceGrid
              title="Videos"
              viewAllUrl="/videos/"
              overrideColor="text-aqua"
              cols={3}
              resources={videos.edges.map(({ node: { frontmatter } }) => ({
                title: frontmatter.title,
                url: `/videos/${frontmatter.slug}`,
                thumbnailUrl: frontmatter.heroImage,
                type: 'video',
              }))}
            />
          )}

          {events.edges.length > 0 && (
            <ResourceGrid
              title="Events"
              viewAllUrl="/events"
              overrideColor="text-aqua"
              cols={3}
              noTag
              resources={events.edges.map(({ node: { frontmatter } }) => ({
                title: frontmatter.title,
                date: frontmatter.date,
                thumbnailUrl: frontmatter.heroImage,
                url: `/events/${frontmatter.slug}`,
                type: 'event',
                excerpt: frontmatter.description,
                eventLocation: frontmatter.location,
              }))}
            />
          )}

          {pressReleases.edges.length > 0 && (
            <ResourceGrid
              title="Press Releases"
              viewAllUrl="/press"
              overrideColor="text-aqua"
              cols={3}
              resources={pressReleases.edges.map(
                ({ node: { frontmatter } }) => ({
                  title: frontmatter.title,
                  url: `/press/${frontmatter.slug}`,
                  thumbnailUrl: frontmatter.featuredImage,
                  type: 'press-release',
                }),
              )}
            />
          )}

          <NewsletterCtaSection headline={page.newsletterCta?.headline} />

          <CtaSection headline={page.cta?.headline} />
        </>
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query ResourcesPageQuery(
    $id: String!
    $featuredResourceId: String
    $popularResourceIds: [String]
  ) {
    page: markdownRemark(id: { eq: $id }) {
      frontmatter {
        heroTitle
        seo {
          title
          description
          openGraphImage
        }
        newsletterCta {
          headline
        }
        cta {
          headline
        }
      }
    }
    featuredResource: markdownRemark(id: { eq: $featuredResourceId }) {
      frontmatter {
        title
        slug
        featuredImage
        description
        resourceType
      }
    }
    popularResources: allMarkdownRemark(
      filter: { id: { in: $popularResourceIds } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            resourceType
          }
        }
      }
    }
    siteSearchIndex {
      index
    }
    posts: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "blog-post" }
          status: { eq: "public" }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
      limit: 4
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            featuredImage
            description
          }
        }
      }
    }
    reports: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "report" }, status: { eq: "public" } }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            heroImage
            description
          }
        }
      }
    }
    customerStories: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "customer-story" }
          status: { eq: "public" }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            featuredImage
            resourceType
            description
          }
        }
      }
    }
    downloads: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "download" }
          status: { eq: "public" }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
      limit: 6
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            heroImage
            description
          }
        }
      }
    }
    pressReleases: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "press-release" }
          status: { eq: "public" }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            featuredImage
            description
          }
        }
      }
    }
    videos: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "video" }, status: { eq: "public" } }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            heroImage
          }
        }
      }
    }
    events: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "event" }, status: { eq: "public" } }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            date
            location
            heroImage
            description
          }
        }
      }
    }
    productUpdates: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "product-update" }
          status: { eq: "public" }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
      limit: 6
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            date
          }
        }
      }
    }
  }
`;

export default ResourcesPage;
