import { Link } from 'gatsby';
import React from 'react';
import DownloadLink from 'components/molecules/DownloadLink';
import * as moment from 'moment';
import cn from 'classnames';

interface DownloadResource {
  title: string;
  url: string;
}

interface ProductUpdate {
  publishDate: string;
  title: string;
  url: string;
}

interface TwoColumnResourceListProps {
  downloads: DownloadResource[];
  productUpdates: ProductUpdate[];
}

const TwoColumnResourceList: React.FC<TwoColumnResourceListProps> = ({
  downloads = [],
  productUpdates = [],
}) => {
  const hasTwoCols = downloads?.length > 0 && productUpdates?.length > 0;

  return (
    <div className="u-section bg-aqua-light">
      <div className="u-container lg:flex py-20 md:py-32 px-6 lg:px-md xl:px-0 space-y-16 lg:space-y-0">
        {downloads?.length > 0 && (
          <div
            className={cn('w-full flex-shrink-0', {
              'lg:w-1/2': hasTwoCols,
            })}
          >
            <h2 className="mb-6 md:mb-10 flex items-center justify-between u-h2">
              Downloads
            </h2>
            <div className="mt-6 md:mt-12 md:flex space-y-4 md:space-y-0 md:space-x-8">
              <div className="md:w-1/2 space-y-4 md:space-y-8">
                {downloads
                  .slice(0, Math.floor(downloads.length / 2))
                  .map((download, index) => (
                    <DownloadLink key={index} {...download} />
                  ))}
              </div>
              <div className="md:w-1/2 space-y-4 md:space-y-8">
                {downloads
                  .slice(Math.floor(downloads.length / 2))
                  .map((download, index) => (
                    <DownloadLink key={index} {...download} />
                  ))}
              </div>
            </div>
            <div className="mt-6 md:mt-14">
              <Link to="/downloads" className="u-cta-link">
                See more downloads
              </Link>
            </div>
          </div>
        )}
        {productUpdates?.length > 0 && (
          <div
            className={cn('w-full flex-shrink-0', {
              'lg:pl-8 lg:w-1/2': hasTwoCols,
            })}
          >
            <h2 className="mb-6 md:mb-10 flex items-center justify-between u-h2">
              Product Updates
            </h2>

            <div className="mt-6 md:mt-12">
              <div className="space-y-4">
                {productUpdates.map((productUpdate, index) => (
                  <Link
                    to={productUpdate.url}
                    key={index}
                    className="flex items-center space-x-4 text-lg lg:text-xl leading-tight transition hover:opacity-50 focus:opacity-50"
                  >
                    <div className="font-semibold">
                      {moment(productUpdate.publishDate).format('MMMM YYYY')}
                    </div>
                    <div>•</div>
                    <div className="text-mid-grey opacity-60">
                      {productUpdate.title}
                    </div>
                  </Link>
                ))}
              </div>
              <div className="mt-6 md:mt-14">
                <Link to="/product-updates" className="u-cta-link">
                  See more product updates
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TwoColumnResourceList;
