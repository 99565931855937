import { Index } from 'elasticlunr';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { getResourceUrl } from 'helpers/resources';
import FullWidthResourceCard from './FullWidthResourceCard';
import NewsletterCtaSection from './NewsletterCtaSection';

interface SearchResultsProps {
  query: string;
  index: any;
}

const SearchResults: React.FC<SearchResultsProps> = ({ query, index }) => {
  const [results, setResults] = useState<any[]>([]);
  const indexRef = useRef(Index.load(index));

  useEffect(() => {
    setResults(
      indexRef.current
        ? indexRef.current
            .search(query, { expand: true } as any)
            .map(({ ref }) => indexRef.current.documentStore.getDoc(ref))
        : [],
    );
  }, [query]);

  return (
    <div>
      {results.length > 0 ? (
        <div className="px-6 my-20 u-container md:my-32 lg:px-md">
          {results.slice(0, 3).map((node, index) => (
            <FullWidthResourceCard
              key={index}
              title={node.title}
              date={node.date}
              imageUrl={node.featuredImage}
              url={getResourceUrl(node.resourceType, node.slug)}
              type={node.resourceType}
              description={node.description}
            />
          ))}
        </div>
      ) : (
        <div className="px-6 my-20 u-container md:my-32 lg:px-md">
          No search results match your query
        </div>
      )}
      <NewsletterCtaSection
        headline="Skupos is sweeping the c-store nation. Send the latest stories to your inbox."
        background="bg-violet-alt text-white"
      />
      {results.length > 3 && (
        <div className="px-6 my-20 u-container md:my-32 lg:px-md">
          {results.slice(3).map((node, index) => (
            <FullWidthResourceCard
              key={index}
              title={node.title}
              date={node.date}
              imageUrl={node.featuredImage}
              url={getResourceUrl(node.resourceType, node.slug)}
              type={node.resourceType}
              description={node.description}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchResults;
