import React from 'react';
import useNewsletter from 'hooks/use-newsletter';
import ArrowIcon from 'components/atoms/ArrowIcon';

const NewsletterBox: React.FC = () => {
  const {
    values: { email },
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    error,
    response,
  } = useNewsletter();

  const ErrorMessage = ({ name }: { name: string }) => {
    return errors[name] && touched[name] ? (
      <div className="text-sm text-red-500 mt-4">{errors[name]}</div>
    ) : null;
  };

  return (
    <div className="mt-14 bg-white pt-7 pb-10 px-10 NewsletterBox">
      <h3 className="text-xl mb-2 font-bold tracking-tightest leading-8">
        Get the latest from Skupos
      </h3>
      {response?.ok ? (
        <p className="text-lg tracking-tighter">Thank you for subscribing</p>
      ) : (
        <form
          className="relative border-b border-dark-grey border-opacity-20 flex "
          onSubmit={handleSubmit}
        >
          <input
            className="flex-grow px-0 py-2.5 leading-none text-lg font-semibold tracking-tighter focus:outline-none"
            type="email"
            name="email"
            placeholder="Enter your email address"
            value={email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <button
            type="submit"
            disabled={isSubmitting}
            className={`text-aqua px-2 ${isSubmitting ? 'opacity-50' : ''}`}
          >
            <ArrowIcon />
          </button>
        </form>
      )}
      <ErrorMessage name="email" />
      {error && (
        <div className="text-red-500 mt-4">{response.data?.message}</div>
      )}
    </div>
  );
};

export default NewsletterBox;
