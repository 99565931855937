import { ResourceType } from '../types/resource-type';

interface StringMap {
  [key: string]: string;
}

export const getResourceUrl = (
  resourceType: ResourceType,
  slug: string,
): string => {
  const urlMap: StringMap = {
    'blog-post': `/blog/${slug}`,
    'press-release': `/press/${slug}`,
    'customer-story-brand': `/customer-stories/${slug}`,
    'customer-story-retail': `/customer-stories/${slug}`,
    report: `/reports/${slug}`,
    'case-study': `/customer-stories/${slug}`,
    video: `/videos/${slug}`,
    download: `/downloads/${slug}`,
  };

  return urlMap[resourceType];
};
